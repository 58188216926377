// import { TargetElement } from "./../../Functions/TargetElement";
import Icon01 from "./../../assets/images/svg/brifecase-timer.svg";
import Icon02 from "./../../assets/images/svg/award.svg";

// social-media icons with arrow down
import Facebook from "./../../assets/images/svg/facebook.svg";
import Instagram from "./../../assets/images/svg/instagram.svg";
import Twitter from "./../../assets/images/svg/twitter.svg";
import arrowDown from "./../../assets/images/svg/angle-down.svg";

const Home = () => {
    const data = {
        heading: "WELCOME TO AWDS",
        description:
            "BEST COMPANY FOR CREATE YOUR WEBSITE BY PROFESSIONAL TEEMS  WITH USING NEW TECHNOLOGY",
        boxText: "BEST COMPANY FOR  CREATE YOUR  WEBSITE",
    };
    const box = [
        {
            key: 1,
            icon: Icon01,
            alt: "briefcase timer icon",
            heading: "TIME",
            description: "At 24H  as weekly",
        },
        {
            key: 2,

            icon: Icon02,
            alt: "award icon",
            heading: "PREMIUM",
            description: "products with Quality",
        },
    ];
    const social = [
        {
            key: 1,
            icon: Facebook,
            to: "https://facebook.com",
            title: "Facebook",
        },
        {
            key: 2,
            icon: Instagram,
            to: "https://instagram.com",
            title: "Instagram",
        },
        {
            key: 3,
            icon: Twitter,
            to: "https://twitter.com",
            title: "Twitter",
        },
        {
            key: 4,
            icon: arrowDown,
            to: "#serves",
            title: "Down",
        },
    ];

    return (
        <div className="f-i-Down home animate__animated">
            <div className="container">
                <ul className="f-i-right social-media animate__animated">
                    {social.map((e) => (
                        <li
                            className="f-i-Down animate__animated"
                            title={e.title}
                            key={e.key}
                        >
                            <a href={e.to}>
                                <img src={e.icon} alt={e.title} />
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="heading">
                    <h1 className="f-i-up animate__animated">{data.heading}</h1>
                    <p className="f-i-up animate__animated">
                        {data.description}
                    </p>
                </div>
                <div className="f-i-up box animate__animated">
                    <div className="cards">
                        <div className="overlay-blur"></div>
                        <h3 className="f-i-up animate__animated">
                            {data.boxText}
                        </h3>
                        <div className="cards-box">
                            {box.map((e) => (
                                <div
                                    key={e.key}
                                    className="f-i-up card animate__animated"
                                >
                                    <img
                                        className="f-i-up animate__animated"
                                        src={e.icon}
                                        alt={e.alt}
                                    />
                                    <h6 className="f-i-up animate__animated">
                                        {e.heading}
                                    </h6>
                                    <span className="f-i-up animate__animated">
                                        {e.description}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Home;
