import React from "react";
const DirRtl = () => {
    document.body.dir = "rtl";
};
const DirLtr = () => {
    document.body.dir = "ltr";
};
const Dir = () => {
    return (
        <>
            <div className="op op-direction">
                <button
                    id="ltr"
                    // className="active"
                    onClick={DirLtr}
                >
                    LTR
                </button>
                <button id="rtl" onClick={DirRtl}>
                    RTL
                </button>
            </div>
        </>
    );
};
export default Dir;
