const Theme = () => {
    const theme = [
        {
            Id: 1,
            value: "dark",
            textContent: "Dark",
            mainColor: "#070b0e",
            darkSecondeColor: "#fff",
            socialColor: "#d3e2f0",
            darkWhiteColor: "#ffffff33",
            darkShadowColor: " #000000a1",
            darkHoverColor: "#ffffff10",
        },
        {
            Id: 2,
            value: "light",
            textContent: "Light",
            mainColor: "#f1f3f6",
            darkSecondeColor: "#070b0e",
            socialColor: "#d3e2f0",
            darkWhiteColor: "#ffffff33",
            darkShadowColor: " #000000a1",
            darkHoverColor: "#ffffff10",
        },
        {
            Id: 3,
            value: "orange",
            textContent: "Orange",
            mainColor: "#f18600",
            darkSecondeColor: "#000",
            socialColor: "#d3e2f0",
            darkWhiteColor: "#ffffff33",
            darkShadowColor: " #000000a1",
            darkHoverColor: "#ffffff10",
        },
        {
            Id: 4,
            value: "darkBlue",
            textContent: "Dark Blue",
            mainColor: "#22294e",
            darkSecondeColor: "#fff",
            socialColor: "#d3e2f0",
            darkWhiteColor: "#ffffff33",
            darkShadowColor: " #000000a1",
            darkHoverColor: "#ffffff10",
        },
    ];
    return (
        <>
            <ul className="ulTheme">
                {theme.map((th) => (
                    <li
                        key={th.Id}
                        value={th.value}
                        onClick={() => {
                            document.body.id = th.value;
                        }}
                    >
                        {th.textContent}
                    </li>
                ))}
            </ul>
        </>
    );
};
export default Theme;
