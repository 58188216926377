import { useState, useEffect } from "react";
export const TargetElements = (TargetElement, TargetClass) => {
    return useEffect(() => {
        /* Set Target Element At Variable*/
        /* Function For Checking If The Element Visible At Viewport */
        window.addEventListener("load", () => {
            const ElementTarget = document.querySelectorAll(`${TargetElement}`);
            const Check = () => {
                ElementTarget.forEach((e) => {
                    e.getBoundingClientRect().bottom <= window.innerHeight &&
                        e.classList.add(`${TargetClass}`);
                });
                // FIXME: Old Function
                // ElementTarget.getBoundingClientRect().bottom <=
                //     window.innerHeight &&
                //     ElementTarget.classList.add(`${TargetClass}`);
            };
            window.addEventListener("scroll", Check);
        });
    });
};
/* Function For Add Class To Group Elements 
    - this function added at V0.0.05
*/
/* Get Elements Group By [ ...elements ] & Get Target Class By [ Class ] */
export const ClassToGroups = (Class = "", ...elements) => {
    /* function add for using at window is load */
    const add = () => {
        /* Set All Elements [ ...elements ] At Variable */
        const allElements = document.querySelectorAll(`${elements}`);
        /* Loop At Elements To Set Target Class [ Class ] */
        allElements.forEach((element) => {
            /* Set Target Class [ Class ] At Elements [ element ] */
            element.classList.add(`${Class}`);
        });
    };
    /* Run Add Function When The Window Is Load */
    window.addEventListener("load", add);
};

/* Function For Slid 
    - this function added at V0.0.08
*/

export function Slide($delay) {
    const count = [1, 2, 3, 4];
    const [index, setIndex] = useState(0);
    const delay = $delay;
    return useEffect(() => {
        window.addEventListener(
            "load",
            () => {
                setTimeout(
                    () =>
                        setIndex((prevIndex) =>
                            prevIndex === count.length - 1 ? 0 : prevIndex + 1
                        ),
                    delay
                );
                return () => {};
            },
            [index]
        );
    });
}
