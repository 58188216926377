/* 
    TODO:* search how to create slid bar for complete design
    TODO:* fix-color for toggle menu
*/
// import tailwindConfig from "../tailwind.config";
import React, { useEffect } from "react";
import { ClassToGroups } from "./Functions/TargetElement";

import SettingBox from "./Components/Setting/Setting_Box";
import Loader from "./Components/Loader/Loader";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Event from "./Components/Event/Event";
import favicon from "./assets/images/favicon/favicon.svg";

import "animate.css";
import "./Colors.css";
import "./Style.css";
/* include style rtl */
import "./Dir/Dir_RTL.css";
/* include style file */
import "./theme/Orange.css";
import "./theme/Dark-blue.css";
import "./theme/Dark.css";
import "./theme/Light.css";

/* Start Set Animate For Elements */
/* Set Animate By Using ClassToGroups Function */
const FadeInDown = [".f-i-Down"];
const FadeInUp = [".f-i-up"];
const FadeInRight = [".f-i-right"];
const FadeInLeft = [".f-i-left"];
ClassToGroups("animate__fadeInDown", FadeInDown);
ClassToGroups("animate__fadeInUp", FadeInUp);
ClassToGroups("animate__fadeInRight", FadeInRight);
ClassToGroups("animate__fadeInLeft", FadeInLeft);
/* End Set Animate For Elements */
const App = () => {
    const documentData = {
        title: "AWDS Company",
        favicon: favicon,
        description: "Welcome To AWDS Compony",
        author: "AWDS",
        direction: "ltr",
    };

    useEffect(() => {
        document.title = documentData.title;
        document.querySelector("head [rel='icon']").href = documentData.favicon;
        document.querySelector("head [name='description']").content =
            documentData.description;
        document.body.dir = documentData.direction;
    });

    return (
        <div className="App ">
            <Loader />
            <SettingBox />
            <Header Logo="Logo" LogoTo="#*" />
            <Home />
            <Event />
        </div>
    );
};

export default App;
