import Slide from "./Slide";
const Event = () => {
    return (
        <>
            <div id="#Event"></div>
            <div className="Event">
                <div className="container">
                    <Slide />
                </div>
            </div>
        </>
    );
};
export default Event;
