import ImgOne_550_1050 from "./../../assets/images/png/1.png";
import ImgTwo_550_1050 from "./../../assets/images/png/2.png";
import ImgThree_550_1050 from "./../../assets/images/png/3.png";
import React, { useState, useEffect } from "react";
import { TargetElements } from "./../../Functions/TargetElement";
import Lottie from "react-lottie";
import lottieFile from "./../../assets/Lottie-file/party.json";
// TODO: convert to function at targetElement.js
// TODO: focus how the function is worked ? & expelling

const delay = 5000;
const count = [1, 2, 3];
const Slide = () => {
    const [index, setIndex] = useState(0);
    const timeoutRef = React.useRef(null);
    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }
    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === count.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );
        return () => {
            resetTimeout();
        };
    }, [index]);

    const defaultOptions = {
        loop: false,
        autoplay: false,
        animationData: lottieFile,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    useEffect(() => {
        const targetParent = document.querySelector(".slide_img");
        window.addEventListener("scroll", () => {
            if (
                targetParent.getBoundingClientRect().bottom < window.innerHeight
            ) {
                // document.body.id = "dark";
                defaultOptions.autoplay = true;
            } else {
                // document.body.id = " ";
            }
        });
    });
    const SlideContent = [
        {
            key: 1,
            img: ImgOne_550_1050,
            alt: "ImageOne",
            absoluteText: "Quality Product",
            TextContent:
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates illo temporibus minima nisi debitis id reiciendis explicabo nemo molestiae mollitia voluptatem sunt repudiandae, distinctio qui corrupti, aut nesciunt vel nam!  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates illo temporibus minima nisi debitis id reiciendis explicabo nemo molestiae mollitia voluptatem sunt repudiandae, distinctio qui corrupti, aut nesciunt vel nam!  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates illo temporibus minima nisi debitis id reiciendis explicabo nemo molestiae mollitia voluptatem sunt repudiandae, distinctio qui corrupti, aut nesciunt vel nam!",
            buttonText: "LEARN MORE",
        },
        {
            key: 2,
            img: ImgTwo_550_1050,
            alt: "ImageTwo",
            absoluteText: "Growth Strategy",
            TextContent:
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates illo temporibus minima nisi debitis id reiciendis explicabo nemo molestiae mollitia voluptatem sunt repudiandae, distinctio qui corrupti, aut nesciunt vel nam!  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates illo temporibus minima nisi debitis id reiciendis explicabo nemo molestiae mollitia voluptatem sunt repudiandae, distinctio qui corrupti, aut nesciunt vel nam!  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates illo temporibus minima nisi debitis id reiciendis explicabo nemo molestiae mollitia voluptatem sunt repudiandae, distinctio qui corrupti, aut nesciunt vel nam!",
            buttonText: "LEARN MORE",
        },
        {
            key: 3,
            img: ImgThree_550_1050,
            alt: "ImageThree",
            absoluteText: "Competitive Price",
            TextContent:
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates illo temporibus minima nisi debitis id reiciendis explicabo nemo molestiae mollitia voluptatem sunt repudiandae, distinctio qui corrupti, aut nesciunt vel nam!  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates illo temporibus minima nisi debitis id reiciendis explicabo nemo molestiae mollitia voluptatem sunt repudiandae, distinctio qui corrupti, aut nesciunt vel nam!  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates illo temporibus minima nisi debitis id reiciendis explicabo nemo molestiae mollitia voluptatem sunt repudiandae, distinctio qui corrupti, aut nesciunt vel nam!",
            buttonText: "LEARN MORE",
        },
    ];

    TargetElements(".Event-slideshow", "animate__fadeInRight");

    return (
        <>
            <Lottie id="Lottie" options={defaultOptions} speed={1} />
            <div className="Event-slideshow  animate__animated">
                <div className="slideshowDots">
                    {count.map((_, idx) => (
                        <div
                            key={idx}
                            className={`slideshowDot ${
                                index === idx ? "active" : " "
                            }`}
                            onClick={() => {
                                setIndex(idx);
                            }}
                        ></div>
                    ))}
                </div>
                <div
                    className="Event-slideshowSlider"
                    style={{
                        transform: `translate3d(${-index * 100}%, 0 , 0)`,
                    }}
                >
                    {SlideContent.map((content) => (
                        <div
                            key={content.key}
                            className="slideshowSlider_slide"
                        >
                            <div className=" slide_img ">
                                <h3 className="  title">
                                    {content.absoluteText}
                                </h3>
                                <div className="img">
                                    <img src={content.img} alt={content.alt} />
                                </div>
                            </div>
                            <div className="slide_text-content">
                                <p>{content.TextContent}</p>
                                <button>{content.buttonText}</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
export default Slide;
