import Dir from "./Dir";
import Theme from "./Theme";
const Setting = () => {
    return (
        /* TODO: Set Button Up Menu To Transform Scale For Menu From To */
        <div
            id="setting"
            className="animate__animated animate__fadeInLeft animate__delay-1s"
        >
            <h4>Setting Site</h4>

            <span className="hr"></span>
            <h6> # Direction</h6>
            <Dir />
            <span className="hr"></span>
            <h6> # Theme</h6>
            <Theme />
        </div>
    );
};
export default Setting;
