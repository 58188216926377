import React, { useEffect } from "react";
import Lottie from "react-lottie";
import LoaderIcon from "./../../assets/Lottie-file/cube-loader.json";

const Loader = () => {
    useEffect(() => {
        const Loader = document.querySelector("#Loader");
        window.addEventListener("load", () => {
            Loader.style = `display : none`;
        });
    });
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoaderIcon,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <div id="Loader" className="Loader">
            <Lottie options={defaultOptions} width={250} height={170} />
            <h3>A W D S</h3>
        </div>
    );
};
export default Loader;
