import React from "react";

/* icons */
import telIcon from "./../../assets/images/svg/call.svg";
import melIcon from "./../../assets/images/svg/mail.svg";
/* icons */
const Header = (props) => {
    const Links = [
        { key: 1, text: "HOME", to: "#1" },
        { key: 2, text: "ABOUT", to: "#2" },
        { key: 3, text: "CONTACT", to: "#3" },
        { key: 4, text: "SERVICES", to: "#4" },
        { key: 5, text: "EVENTS", to: "#5" },
        { key: 6, text: "FAQs", to: "#6" },
    ];
    const contact = {
        tel: {
            icon: telIcon,
            alt: "Telephone Icon",
            text: "+0 ( 123 )- 456 - 789",
        },
        mel: {
            icon: melIcon,
            alt: "Email Icon",
            text: "exsample@mail.com",
        },
    };
    const btnContent = {
        btnOne: {
            text: "Login",
            to: "#*",
        },
        btnTwo: {
            text: "Register",
            to: "#*",
        },
    };

    return (
        <>
            <header>
                <div className="btn-toggle">
                    <button
                        onClick={() => {
                            const header = document.querySelector("header");
                            const cont = 50;
                            header.clientHeight > cont
                                ? (header.style = `max-height: 50px !important; overflow:hidden;`)
                                : (header.style = `min-height: 100vh  !important; overflow: visible;`);
                        }}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div className="f-i-Down d-header animate__animated">
                    <div className="links">
                        <ul>
                            {Links.map((link) => (
                                <li
                                    key={link.key}
                                    className="f-i-Down d-header_li animate__animated"
                                >
                                    {link.text}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="logo ">
                        <a href={props.LogoTo}>{props.Logo}</a>
                    </div>
                    <div className="links">
                        <ul>
                            <li className="f-i-Down links_li animate__animated">
                                <a href="#*">
                                    <img
                                        className="f-i-Down animate__animated"
                                        src={contact.tel.icon}
                                        alt={contact.tel.alt}
                                    />
                                    <span>{contact.tel.text}</span>
                                </a>
                            </li>
                            <li className="links_li animate__animated">
                                <a href="#*">
                                    <img
                                        className="f-i-Down  animate__animated"
                                        src={contact.mel.icon}
                                        alt={contact.mel.alt}
                                    />
                                    <span>{contact.mel.text}</span>
                                </a>
                            </li>
                        </ul>
                        <div className="f-i-Down btn-group animate__animated">
                            <a href={btnContent.btnOne.to}>
                                {btnContent.btnOne.text}
                            </a>
                            <a href={btnContent.btnTwo.to}>
                                {btnContent.btnTwo.text}
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};
export default Header;
